//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getMonitorListMArray, getMarketplacesListArray } from "@/utils/enums";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const select_type_items = [...getMonitorListMArray()];
    const select_marketplace_items = [...getMarketplacesListArray()];
    let select_entity_items = [];
    let select_monitor_items = [];

    // Разделяем на 2 массива
    select_type_items.forEach(item => {
      ["product", "brand", "seller", "category"].forEach(el => {
        if (item.id.indexOf(el) === 0) {
          select_entity_items.push({
            id: el,
            title: item.title.split(":")[0]
          });
        } else {
          select_monitor_items.push({
            id: item.id,
            title: item.title.split(":")[1]
          });
        }
      });
    });
    // Удаляем дубликаты
    select_entity_items = this.removeDuplicates(select_entity_items);
    select_monitor_items = this.removeDuplicates(select_monitor_items);
    const initial_new_list = {
      title: "",
      entity: select_entity_items[0],
      monitor: select_monitor_items[0],
      mp: select_marketplace_items[0],
      description: ""
    };
    return {
      initial_new_list,
      new_list: {
        ...initial_new_list
      },
      entity: null,
      mp: null,
      select_type_items,
      select_entity_items,
      select_monitor_items,
      select_marketplace_items,
      loading: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      entity = null,
      mp = null
    } = {}) {
      this.new_list = {
        ...this.initial_new_list
      };
      this.entity = entity;
      if (entity) {
        this.new_list.entity = this.select_entity_items.find(i => i.id === entity);
      }
      this.mp = mp;
      if (mp) {
        this.new_list.mp = this.select_marketplace_items.find(i => i.id === mp);
      }
    },
    removeDuplicates(array) {
      // Declare a new array
      let newArray = [];

      // Declare an empty object
      let uniqueObject = {};

      // Loop for the array elements
      for (let i in array) {
        // Extract the title
        let objTitle = array[i]["id"];

        // Use the title as the index
        uniqueObject[objTitle] = array[i];
      }

      // Loop to push unique object into array
      for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
      }
      return newArray;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _this$new_list$monito, _this$new_list$entity, _this$new_list$mp;
        const monitor_id = (_this$new_list$monito = this.new_list.monitor) === null || _this$new_list$monito === void 0 ? void 0 : _this$new_list$monito.id;
        const entity_id = (_this$new_list$entity = this.new_list.entity) === null || _this$new_list$entity === void 0 ? void 0 : _this$new_list$entity.id;
        // let entity_id;
        // ["product", "brand", "seller", "category"].forEach((el) => {
        //     if (monitor_id.indexOf(el) === 0) entity_id = el;
        // });

        //console.log("monitor_id=" + monitor_id);
        //console.log("entity_id=" + entity_id);

        const result = await this.$store.dispatch("listM/addListM", {
          title: this.new_list.title,
          entity: entity_id,
          monitor: monitor_id,
          mp: (_this$new_list$mp = this.new_list.mp) === null || _this$new_list$mp === void 0 ? void 0 : _this$new_list$mp.id,
          description: this.new_list.description
        });
        this.loading = false;
        close();
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    // Значения второго селекта в зависимости от первого
    current_select_monitor_items() {
      return this.select_monitor_items.filter(item => {
        var _this$new_list$entity2;
        return item.id.startsWith((_this$new_list$entity2 = this.new_list.entity) === null || _this$new_list$entity2 === void 0 ? void 0 : _this$new_list$entity2.id);
      });
    },
    valid() {
      var _this$new_list$title, _this$new_list$entity3, _this$new_list$mp2;
      return ((_this$new_list$title = this.new_list.title) === null || _this$new_list$title === void 0 ? void 0 : _this$new_list$title.length) && ((_this$new_list$entity3 = this.new_list.entity) === null || _this$new_list$entity3 === void 0 ? void 0 : _this$new_list$entity3.id) && ((_this$new_list$mp2 = this.new_list.mp) === null || _this$new_list$mp2 === void 0 ? void 0 : _this$new_list$mp2.id);
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Далее",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  watch: {
    // Выставляем значение по умолчанию при переключении первого селекта
    current_select_monitor_items(value) {
      this.new_list.monitor = value[0];
    }
  },
  components: {
    Modal,
    AppSelect
  }
};