//
//
//
//
//
//
//
//
//
//

import RangeCalendar from "@/components/RangeCalendar.vue";
export default {
  components: {
    RangeCalendar
  },
  props: {
    value: {
      default: undefined
    }
  }
};