//
//
//
//
//
//
//
//

import vuescroll from "vuescroll";
export default {
  data() {
    return {
      ops: {
        rail: {
          size: "10px",
          gutterOfEnds: "0",
          gutterOfSide: "0"
        },
        bar: {
          background: "#999"
        }
      }
    };
  },
  components: {
    vuescroll
  }
};