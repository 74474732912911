//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideYUpTransition } from "vue2-transitions";
import Checkbox from "@/components/Checkbox.vue";
export default {
  props: {
    value: {
      default: undefined
    },
    loader: {
      type: Function,
      required: true
    },
    // User-visible text
    titleProp: {
      type: String,
      default: "title"
    },
    // Prop to compare objects
    idProp: {
      type: String,
      default: "title"
    }
  },
  data() {
    return {
      isOpen: false,
      queryItems: {},
      itemsById: {},
      search: "",
      selected: []
    };
  },
  methods: {
    getCheckboxState(item) {
      return !!this.selected.find(selectedId => selectedId === item[this.idProp]);
    },
    onCheckboxChange(item, v) {
      let selected = this.selected.filter(selectedId => selectedId !== item[this.idProp]);
      if (v) {
        selected.push(item[this.idProp]);
      }
      this.selected = selected;
    },
    closeSelect() {
      this.isOpen = false;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
      this.search = "";
      if (this.isOpen) {
        this.loadItems();
      }
    },
    apply() {
      this.$emit("input", this.selected.length ? this.selected : undefined);
      this.closeSelect();
    },
    async loadItems() {
      if (!this.isOpen) {
        return;
      }
      if (this.queryItems[this.search]) {
        return;
      }
      const items = await this.loader({
        query: this.search
      });
      this.$set(this.queryItems, this.search, items);
      items.forEach(item => {
        this.$set(this.itemsById, item[this.idProp], item);
      });
    },
    async loadItemByIds(ids) {
      const items = await this.loader({
        ids
      });
      items.forEach(item => {
        this.$set(this.itemsById, item[this.idProp], item);
      });
      const failedIds = ids.filter(id => !items.find(item => item[this.idProp] === id));
      if (failedIds) {
        let selected = this.selected.filter(selectedId => !failedIds.includes(selectedId));
        this.$emit("input", selected.length ? selected : undefined);
      }
    }
  },
  computed: {
    title() {
      let selectedItems = (this.value || []).map(selectedId => {
        var _this$itemsById$selec;
        return (_this$itemsById$selec = this.itemsById[selectedId]) === null || _this$itemsById$selec === void 0 ? void 0 : _this$itemsById$selec.title;
      }).filter(i => !!i);
      if (selectedItems.length == 0) return "Не выбрано";
      //! @V: return selectedItems.join(', ')
      return selectedItems.join(" ; "); //!&: 2022-03-05
    },

    visibleItems() {
      let result = this.queryItems[this.search] || [];
      if (this.search !== "") {
        return result;
      }
      const selected = this.selected;
      selected.forEach(selectedId => {
        if (!result.find(item => item[this.idProp] === selectedId)) {
          const item = this.itemsById[selectedId];
          if (item) {
            result.push(this.itemsById[selectedId]);
          }
        }
      });
      return result;
    }
  },
  watch: {
    search: {
      immediate: true,
      handler() {
        this.loadItems();
      }
    },
    value: {
      immediate: true,
      handler(value) {
        this.selected = value || [];
      }
    },
    selected: {
      immediate: true,
      handler(v) {
        const notLoadedIds = v.filter(selectedId => !this.itemsById[selectedId]);
        if (notLoadedIds.length) {
          this.loadItemByIds(notLoadedIds);
        }
      }
    }
  },
  // mounted() {
  //     this.items = [...this.modelValue]
  // },
  components: {
    SlideYUpTransition,
    Checkbox
  }
};