//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  computed: {
    model: {
      get() {
        return this.value === undefined ? "" : this.value;
      },
      set(v) {
        this.$emit("input", [""].includes(v) ? undefined : v);
      }
    }
  }
};