//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Array,
      default: undefined
    },
    range: {
      type: Array,
      default() {
        return ["", ""];
      }
    }
  },
  computed: {
    model: {
      get() {
        return this.value === undefined ? ["", ""] : this.value;
      },
      set(v) {
        if (v[0] === "" && v[1] === "") {
          this.$emit("input", undefined);
          return;
        }
        this.$emit("input", v);
      }
    }
  }
};