//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import ModalAddListM from "@/components/modals/ModalAddListM.vue";
import ModalListImportResult from "@/components/modals/ModalListImportResult.vue";
import { getEntitiesList, getMarketplacesList } from "@/utils/enums";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      entity: "",
      mp: "",
      items: [],
      loading: false,
      onComplete: null,
      lists: null,
      selectedListId: null,
      saveFunction: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      items,
      saveFunction,
      entity,
      monitor,
      mp,
      onComplete
    }) {
      this.entity = entity;
      this.monitor = monitor;
      this.mp = mp;
      this.items = items;
      this.saveFunction = saveFunction;
      this.onComplete = onComplete;
      this.loadListMs();
    },
    async loadListMs() {
      const response = await this.$store.dispatch("listM/getListMs", {
        page_size: 500,
        entity: this.entity,
        monitor: this.monitor,
        mp: this.mp
      });
      const lists = response.items;
      this.lists = lists;
    },
    selectList(id) {
      this.selectedListId = id;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        let result;
        if (this.saveFunction) {
          result = await this.saveFunction({
            listId: this.selectedListId
          });
        } else {
          result = await this.$store.dispatch("listM/listMImport", {
            id: this.selectedListId,
            lines: this.items.map(i => `#${i[this.idProp]}`).join("\n")
          });
        }
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
        this.$refs.modalListImportResult.open({
          list: this.selectedList,
          result
        });
      } catch (e) {
        this.loading = false;
      }
    },
    openNewListMModal() {
      this.$refs.modalAddListM.open({
        mp: this.mp,
        entity: this.entity
      });
    },
    onListMAdded(item) {
      this.lists.push(item);
      this.selectedListId = item.id;
    }
  },
  computed: {
    selectedList() {
      if (!this.selectedListId || !this.lists) {
        return null;
      }
      return this.lists.find(item => item.id === this.selectedListId);
    },
    emptyText() {
      const entity = getEntitiesList("manyGenitive", "lower")[this.entity];
      const mp = getMarketplacesList()[this.mp];
      return `Вы пока не создали ни одного списка ${entity} ${mp}`;
    },
    idProp() {
      return getEntitiesList("idProp", null)[this.entity];
    },
    valid() {
      return !!this.selectedListId;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Добавить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal,
    ModalAddListM,
    ModalListImportResult
  }
};