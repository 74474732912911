//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideXRightTransition } from "vue2-transitions";
import AppSelect from "@/components/AppSelect.vue";
import FiltersSelect from "@/components/FiltersSelect.vue";
import * as filtersComponents from "./Filter";
import { disableBodyScroll, enableBodyScroll } from "@/utils";
import { cloneDeep } from "lodash";

// import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/default.css'

export default {
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    modelValue: {
      required: true
    },
    available: {
      type: Array,
      required: true
    },
    tableId: {
      type: String,
      required: true
    },
    ranges: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    let filterValues = {};
    if (this.modelValue && typeof this.modelValue === "object") {
      filterValues = cloneDeep(this.modelValue);
    }
    return {
      filters_select: null,
      filters_model: null,
      scrollSettings: {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false
      },
      tableData: [],
      // available: [],
      show_confirm_label: false,
      filter_name: "",
      filterValues
    };
  },
  methods: {
    getComponent(type) {
      return filtersComponents[type];
    },
    getComponentProps(filter) {
      return {
        ...filter,
        type: undefined
      };
    },
    close() {
      this.$emit("close");
    },
    async saveFilter() {
      this.$access.hasOrThrow("filterPresets");
      const preset_title = this.filter_name;
      if (!preset_title.length) {
        return;
      }
      await this.$store.dispatch("table/putFilterPreset", {
        table_id: this.tableId,
        preset_title: preset_title,
        data: this.cleanFilters
      });
      const presets = (this.filters_select || []).filter(i => i.preset_title !== preset_title);
      const presetObject = {
        preset_title,
        title: preset_title
      };
      presets.push(presetObject);
      this.$set(this, "filters_select", presets);
      this.filters_model = presetObject;
      this.show_confirm_label = true;
    },
    onInputPresetName() {
      this.show_confirm_label = false;
    },
    reset() {
      this.filter_name = "";
      this.filters_model = null;
      this.$set(this, "filterValues", {});
    },
    apply() {
      this.$emit("change", cloneDeep(this.cleanFilters));
      this.close();
    },
    async loadPresets() {
      if (this.$access.getError("filterPresets")) {
        return;
      }
      const items = await this.$store.dispatch("table/getFilterPresetList", {
        table_id: this.tableId
      });
      this.filters_select = items.map(i => {
        return {
          ...i,
          title: i.preset_title
        };
      });
    },
    async onPresetSelect(presetItem) {
      if (presetItem.disabled) {
        return;
      }
      const preset = await this.$store.dispatch("table/getFilterPreset", {
        table_id: this.tableId,
        preset_title: presetItem.preset_title
      });
      this.filters_model = presetItem;
      this.filter_name = presetItem.preset_title;
      this.$set(this, "filterValues", preset.filters);
    }
  },
  computed: {
    cleanFilters() {
      const result = {};
      Object.keys(this.filterValues).forEach(key => {
        if (this.filterValues[key] !== undefined) {
          result[key] = this.filterValues[key];
        }
      });
      return result;
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (this.filters_select === null) {
          this.loadPresets();
        }
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    },
    modelValue(newVal) {
      this.filterValues = cloneDeep(newVal);
    },
    filterValues() {
      this.show_confirm_label = false;
    }
  },
  components: {
    SlideXRightTransition,
    AppSelect,
    // VueSlider,
    FiltersSelect
  }
};